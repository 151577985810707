<template>
  <div>
    <form @submit.prevent="updateUser" class="mt-4">
      <div class="row">
        <div class="col-3 mb-3">
          <label for="title" class="form-label"> Nom complet :</label>
          <input
            type="text"
            id="name"
            v-model="user.name"
            class="form-control"
            required
          />
        </div>

        

        <div class="col-3 mb-3">
          <label for="email" class="form-label"> E-mail :</label>
          <input
            type="text"
            id="email"
            v-model="user.email"
            class="form-control"
            
          />
        </div>

        <div class="col-3 mb-3">
          <label for="title" class="form-label"> Mot de passe :</label>
          <input
            type="password"
            id="password"
            v-model="user.password"
            class="form-control"
            required
          />
        </div>
      </div>

      <button type="submit" class="btn btn-primary">
        Enregistrer l'article
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  beforeCreate() {
    this.$store.dispatch("user/find", this.$route.params.id);
  },
  computed: {
    ...mapGetters("user", { user: "getUser" }),
  },
  methods: {
    updateUser() {
      this.$store.dispatch("user/update", this.user);
    },
  },
};
</script>
